import {
  inject,
  Injectable
} from '@angular/core';
import {ServerSiteServices} from '../servers/server-site.services';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageControllersSiteServices {
  public SERVER_NAME: string = 'controllers-site';
  public DOMAIN: string = 'art-club.artas.org.ua/controllers-site';
  public route: string = 'controllers-site';
  public site: any
  constructor(public server:ServerSiteServices) {
    this.getSite();
  }

 public getSite(callback:any = null) {
      return this.getSiteFromServer(callback);
  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
     this.site = site
     this.server.saveSite(this.DOMAIN, this.site);
  }




  public getSiteFromServer(callback:any = null) {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: any): void => {
      this.site = responseSite;
      callback && callback(responseSite)
    });
    return this.site;
  }
  getCards(){
    return  this.site.organizer.messenges;
  }
  findCard(slug:any){
    let cards = this.getCards();
    let findCard =   cards.filter((itemProduct:any)=>{
      if(itemProduct.id === slug){
        return itemProduct;
      }
    });
    return findCard[0];
  }
}
