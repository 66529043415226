import {Component} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {PageControllersSiteServices} from "../../../services/pages/page-controllers-site.services";

@Component({
  selector: 'app-page-controller-site',
  templateUrl: './page-controller-site.component.html',
  standalone:true,
  imports: [
    NgForOf,
    NgIf,
  ],
})
export class PageControllerSiteComponent {
  constructor(
    public dataPage: PageControllersSiteServices,
              ) {}
  ngOnInit(){
    this.dataPage.getSite((site:any)=>{
      console.log(site,'ControllersSite')
    })
  }
}
