import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {NgIf} from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  imports:[
    NgIf
  ],
  standalone: true,
})
export class HeaderComponent implements OnInit{

  @Input() headerText: string  = 'Free Online Digital Art Courses';
  @Input() subHeaderText: string = 'Free Online Digital Art Courses';
  @Input() messengesName: string ='Courses';
  @Input() arraysName: string ='Lessons';
  @Input() actionText: string ='New lesson or course every week or month!';
  @Input() animationClass: string  ='animate-pulse animate-infinite';
  @Input() countMessenges: number  = 1;
  @Input() countArrays: number  = 1;
  @Input() showCounters: boolean  =false;
  @Input() autoHide: boolean  =false;
  @Input() hideContent: boolean  =true;
  ngOnInit(): void {
    this.autoHideComponent();
  }

  autoHideComponent(){
    if(this.autoHide){
      setTimeout(()=>{this.hideContent = true},3000)
    }
  }
}
